import { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation, Pagination } from 'swiper/modules';
import { Footer } from "./components/Footer";
import ReactGA from 'react-ga4'
import ReactPlayer from "react-player";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles/app.scss'

function App() {
  return (
    <div className="App">
      <Hero/>
      <News/>
      <div className="scroll">
        <Swiper
          modules={[Autoplay]}
          slidesPerView="auto" // Fit as many slides as possible
          spaceBetween={20} // Space between slides
          loop={true} // Enable infinite looping
          freeMode={true} // Enables free scrolling
          autoplay={{
            delay: 0, // No delay, continuous scrolling
            disableOnInteraction: false, // Keep autoplay even after interaction
          }}
          speed={3000} // Adjust the speed for a smooth continuous effect
          grabCursor={true} // Optional: for a better UX
        >
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ao.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/hajimari.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/kuro.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/kuro2.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/sen3.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/sen4.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/zero.webp`} alt="Hero Character" />
        </SwiperSlide>
      </Swiper>
      </div>
      <Store/>
      <div className="scroll scroll-ss">
        <Swiper
          modules={[Autoplay]}
          slidesPerView="auto" // Fit as many slides as possible
          spaceBetween={20} // Space between slides
          loop={true} // Enable infinite looping
          freeMode={true} // Enables free scrolling
          autoplay={{
            delay: 0, // No delay, continuous scrolling
            disableOnInteraction: false, // Keep autoplay even after interaction
          }}
          speed={3000} // Adjust the speed for a smooth continuous effect
          grabCursor={true} // Optional: for a better UX
        >
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss1.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss2.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss3.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss4.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss5.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss6.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss7.webp`} alt="Hero Character" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={`${process.env.PUBLIC_URL}/img/carousel/ss8.webp`} alt="Hero Character" />
        </SwiperSlide>
      </Swiper>
      </div>
      <div className="main">
        <WebsitePortal
          bg="db-ii-bg" image="db-II-logo.png" video="sI7TgkoXqMk" url="https://thelegendofheroes.com/daybreak2/" product="https://thelegendofheroes.com/daybreak2/products"
        />
      </div>
      <div className="other">
        <WebsitePortal
          bg="db-bg" image="db-logo.webp" video="ZDW2Ak5ZSNo" url="https://thelegendofheroes.com/daybreak/" product="https://thelegendofheroes.com/daybreak/products"
        />
                <WebsitePortal
          bg="double-bg" image="db--logo.png" video="XMUXv5g07Rg" url="https://thelegendofheroes.com/tocs/" product="https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iii-the-legend-of-heroes-trails-of-cold-steel-iv"
        />
                <WebsitePortal
          bg="nayuta-bg" image="nayuta-logo.webp" video="y5sX2fY8eH0" url="https://thelegendofnayuta.com/" product="https://thelegendofnayuta.com/#products"
        />
                <WebsitePortal
          bg="reverie-bg" image="reverie-logo.png" video="naEIhb3x7VY" url="https://thelegendofheroes.com/reverie/" product="https://thelegendofheroes.com/reverie/products"
        />
                <WebsitePortal
          bg="azure-bg" image="azure-logo.webp" video="ufcD6GkUYa8" url="https://www.thelegendofheroes.com/crossbell/azure/" product="https://www.thelegendofheroes.com/crossbell/azure/products"
        />
                <WebsitePortal
          bg="zero-bg" image="zero-logo.png" video="S6JVtleWmdg" url="https://www.thelegendofheroes.com/crossbell/zero/" product="https://www.thelegendofheroes.com/crossbell/zero/products"
        />
      </div>
      <Footer/>
    </div>
  );
}

const Hero = () => (
  <section className="hero-section">
    <div className="hero-title">
        <h1>
          THE LEGEND OF HEROES
        </h1>
        <hr />
        <h2>
          PORTAL WEBSITE
        </h2>
    </div>
    <div className="video-container">
      <div className="video-wrapper">
        <ReactPlayer
          className="react-player"
          url={`https://www.youtube.com/watch?v=7lZ_ImNDcI0`}
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <div className="character-section">
      <img src={`${process.env.PUBLIC_URL}/img/char/ao.webp`} alt="Hero Character" />
      <img src={`${process.env.PUBLIC_URL}/img/char/hajimari.webp`} alt="Hero Character" />
      <img src={`${process.env.PUBLIC_URL}/img/char/kuro.webp`} alt="Hero Character" />
      <img src={`${process.env.PUBLIC_URL}/img/char/sen.webp`} alt="Hero Character" />
      <img src={`${process.env.PUBLIC_URL}/img/char/sen3.webp`} alt="Hero Character" />
      <img src={`${process.env.PUBLIC_URL}/img/char/zero.webp`} alt="Hero Character" />
    </div>
  </section>
)

const News = ({title, category, date, url}) => (
  <section className="news-section">
    <div className="news-left">
      <div className="news-title">
        <h2>THE LEGEND OF HEROES</h2>
        <h1>NEWS</h1>
      </div>
      <a href={'https://nisamerica.com/pax/west2024/#demos'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - 
                Daybreak II demo at PAX West`,
            action: `News - 
                Daybreak II demo at PAX West`,
            label: `News - 
                Daybreak II demo at PAX West`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/pax-west.jpg`} alt="News Image" />
          </div>
          <div className="news-text">
              <div className="news-details">
                <h2 className="category" style={{color: "#BF00FF"}}>

                  EVENT
                </h2>
                <h3 className="date">
                  8/09/2024
                </h3>
              </div>
              <div className="article-title">
                Daybreak II demo at PAX West
              </div>
            </div>
        </div>
      </a>
    </div>
    <div className="news-right">
      <a href={'https://store.steampowered.com/news/app/2138610?emclan=103582791473250659&emgid=4156338670609987904'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - Special Patch Notes - The English Release And So Much More`,
            action: `News - Special Patch Notes - The English Release And So Much More`,
            label: `News - Special Patch Notes - The English Release And So Much More`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/update.png`} alt="News Image" />
          </div>
          <div className="news-text">
              <div className="news-details">
                <h2 className="category" style={{color: "#FFAE00"}}>
                  PATCH
                </h2>
                <h3 className="date">
                  7/04/2024
                </h3>
              </div>
              <div className="article-title">
                Special Patch Notes - The English Release And So Much More
              </div>
            </div>
        </div>
      </a>
      <a href={'https://www.youtube.com/watch?v=94Jan2MNG8g'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - Daybreak - Accolades`,
            action: `News - Daybreak - Accolades`,
            label: `News - Daybreak - Accolades`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/accolades.jpg`} alt="News Image" />
          </div>
          <div className="news-text">
              <div className="news-details">
                <h2 className="category" style={{color: "#00D9FF"}}>
                  TRAILER
                </h2>
                <h3 className="date">
                  8/13/2024
                </h3>
              </div>
              <div className="article-title">
                The Legend of Heroes: Trails through Daybreak - Accolades Trailer
              </div>
            </div>
        </div>
      </a>
      <a href={'https://www.youtube.com/watch?v=CDM6IEIJ9CM'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - Toshihiro Kondo `,
            action: `News - Toshihiro Kondo `,
            label: `News - Toshihiro Kondo `,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/kondo.jpg`} alt="News Image" />
          </div>
          <div className="news-text">
            <div className="news-details">
              <h2 className="category" style={{color: "#BF00FF"}}>

                EVENT
              </h2>
              <h3 className="date">
                7/30/2024
              </h3>
            </div>
            <div className="article-title">
              Anime Expo 2024 Trails Series Overview Panel with Toshihiro Kondo 
            </div>
          </div>
        </div>
      </a>
      <a href={'https://www.youtube.com/watch?v=sI7TgkoXqMk'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News -  Daybreak II - Announcement`,
            action: `News -  Daybreak II - Announcement`,
            label: `News -  Daybreak II - Announcement`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/announcement.jpg`} alt="News Image" />
          </div>
          <div className="news-text">
              <div className="news-details">
                <h2 className="category" style={{color: "#00D9FF"}}>
                  TRAILER
                </h2>
                <h3 className="date">
                  7/05/2024
                </h3>
              </div>
              <div className="article-title">
                Trails through Daybreak II - Announcement Trailer (Nintendo Switch, PS4, PS5, PC)
              </div>
            </div>
        </div>
      </a>
      <a href={'https://nisamerica.com/blog/trails-into-reverie-patch-update'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - Reverie - Patch Update`,
            action: `News - Reverie - Patch Update`,
            label: `News - Reverie - Patch Update`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/faq.webp`} alt="News Image" />
          </div>
          <div className="news-text">
              <div className="news-details">
                <h2 className="category" style={{color: "#FFAE00"}}>
                  PATCH
                </h2>
                <h3 className="date">
                  3/25/2024
                </h3>
              </div>
              <div className="article-title">
                The Legend of Heroes: Trails into Reverie - Patch Update (3/26/24)
              </div>
            </div>
        </div>
      </a>
      <a href={'https://nisamerica.com/blog/trails-iii-iv-limited-edition-spotlight'} target="_blank" rel="noopener noreferrer" onClick={()=>{
          ReactGA.event({
            category: `News - III / IV LE`,
            action: `News - III / IV LE`,
            label: `News - III / IV LE`,
          });
        }}>
        <div className="news-card">
          <div className="news-image">
            <img src={`${process.env.PUBLIC_URL}/img/articles/external-1.jpg`} alt="News Image" />
          </div>
          <div className="news-text">
            <div className="news-details">
              <h2 className="category" style={{color: "#00FFA6"}}>

                SPOTLIGHT
              </h2>
              <h3 className="date">
                2/8/2024
              </h3>
            </div>
            <div className="article-title">
              The Legend of Heroes: Trails of Cold Steel III / The Legend of Heroes: Trails of Cold Steel IV - Limited Edition Spotlight
            </div>
          </div>
        </div>
      </a>
    </div>
  </section>
)

 const Store = () => {
    return (
      <section className="store-section">
        <div className="store-left">
          <div className="store-title">
            ONLINE STORE
          </div>
          <div className="store-carousel">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]} // Enable navigation, pagination, and autoplay
            slidesPerView={1} // Show only one slide at a time
            loop={true} // Enable infinite scrolling
            autoplay={{
              delay: 3000, // 3 seconds delay between auto slides
              disableOnInteraction: false, // Continue autoplay even after interaction
            }}
            navigation // Enable navigation arrows
            pagination={{ clickable: true }} // Enable pagination dots
          >
            {
              merch.map((item, index)=>(
                <SwiperSlide key={index}>
                  <StoreItem
                    url={item.url}
                    image={item.image}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
          </div>
        </div>
        <div className="store-right">
          <div className="carousel">
            <div className="store-title">
              PLUSHIES
            </div>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]} // Enable navigation, pagination, and autoplay
              slidesPerView={1} // Show only one slide at a time
              loop={true} // Enable infinite scrolling
              autoplay={{
                delay: 3000, // 3 seconds delay between auto slides
                disableOnInteraction: false, // Continue autoplay even after interaction
              }}
              navigation // Enable navigation arrows
              pagination={{ clickable: true }} // Enable pagination dots
            >
              {
                plush.map((item, index)=>(
                  <SwiperSlide key={index}>
                    <StoreItem
                      url={item.url}
                      image={item.image}
                    />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
          <div className="carousel">
          <div className="store-title">
            ACRYLIC
          </div>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]} // Enable navigation, pagination, and autoplay
            slidesPerView={1} // Show only one slide at a time
            loop={true} // Enable infinite scrolling
            autoplay={{
              delay: 3000, // 3 seconds delay between auto slides
              disableOnInteraction: false, // Continue autoplay even after interaction
            }}
            navigation // Enable navigation arrows
            pagination={{ clickable: true }} // Enable pagination dots
          >
            {
              acrylics.map((item, index)=>(
                <SwiperSlide key={index}>
                  <StoreItem
                    url={item.url}
                    image={item.image}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
          </div>
        </div>
      </section>
    )
 }

 const acrylics = [
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iii-rean-and-altina-acrylic-print',
    image: '/acrylic/cold-steel.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-to-azure-azure-legends-acrylic-print',
    image: '/acrylic/azure.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iv-fallen-hero-acrylic-print',
    image: '/acrylic/fallen-hero.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iv-assemble-at-dawn-acrylic-print',
    image: '/acrylic/assemble.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iv-beautiful-banquet-acrylic-print',
    image: '/acrylic/banquet.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-trusty-part-timer-agnes-claudel-acrylic-stand',
    image: '/acrylic/agnes.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-spriggan-for-hire-van-arkride-acrylic-stand',
    image: '/acrylic/van.webp'
  }
 ]

 const plush = [
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-lil-spriggan-van-plush?_pos=3&_sid=63bb982c7&_ss=r',
    image: '/plush/van-plush.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-lil-part-timer-agnes-plush?_pos=4&_sid=63bb982c7&_ss=r',
    image: '/plush/agnes-plush.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/lil-rogue-c-plush?_pos=17&_sid=63bb982c7&_ss=r',
    image: '/plush/c-plush.webp'
  }
 ]

 const merch = [
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-through-daybreak-ii-limited-edition',
    image: 'db-ii-le.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-of-cold-steel-iii-the-legend-of-heroes-trails-of-cold-steel-iv',
    image: '34-le.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-nayuta-boundless-trails-limited-edition',
    image: 'nayuta-le.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-through-daybreak-ii-chibi-van-keychain?_pos=9&_sid=1da1ed628&_ss=r',
    image: 'van-keychain.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/the-legend-of-heroes-trails-through-daybreak-ii-chibi-agnes-keychain?_pos=8&_sid=1da1ed628&_ss=r',
    image: 'agnes-keychain.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-can-we-see-the-dessert-menu-1000-piece-puzzle?_pos=5&_sid=63bb982c7&_ss=r',
    image: 'daybreak-puzzle.webp'
  },
  {
    url: 'https://store.nisamerica.com/products/trails-through-daybreak-bermottis-bar-set-pint-glass-set-set-of-3?_pos=6&_sid=63bb982c7&_ss=r',
    image: 'cups.webp'
  }
 ]

 const StoreItem = ({image, url}) => (
  <a href={url} target="_blank" rel="noopener noreferrer" onClick={()=>{
    ReactGA.event({
      category: `Store - ${image}`,
      action: `Store - ${image}`,
      label: `Store - ${image}`,
    });
  }}>
    <div className="store-item">
      <img src={`${process.env.PUBLIC_URL}/img/products/${image}`} alt="Product Image" />
    </div>
    <button className="button">
      NISA Online Store
    </button>
  </a>
 )


 const WebsitePortal = ({bg, image, video, url, product}) => {

  const [modal, setModalOpen] = useState(false)

  return (
    <>
      <div className={`modal-container ${modal && "modal-visible"}`}>
        <button className="modal-close" onClick={()=>{setModalOpen(false)}}>
          X
        </button>
        <div className="video-container">
          <div className="video-wrapper">
            <ReactPlayer
              className="react-player"
              url={`https://www.youtube.com/watch?v=${video}`}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
      <div className={`portal-container ${bg}`}>
        {
          bg==="double-bg" ? 
            <div className="double-logo">
              <div className="portal-logo">
                <img className="three-logo " src={`${process.env.PUBLIC_URL}/img/tocs3-logo.webp`} alt={bg + 'logo'} />
              </div>
              <div className="portal-logo">
                <img className="four-logo " src={`${process.env.PUBLIC_URL}/img/tocs-4-logo.webp`} alt={bg + 'logo'} />
              </div>
            </div> :
            <div className="portal-logo">
              <img src={`${process.env.PUBLIC_URL}/img/${image}`} alt={bg + 'logo'} />
            </div>
        }
        <div className="portal-contents">
          <button className="trailer-button" onClick={()=>{setModalOpen(true)}}>
            <img src={`https://img.youtube.com/vi/${video}/maxresdefault.jpg`} alt="" />
          </button>               
          <a href={url} target="_blank" rel="noopener noreferrer" onClick={()=>{
              ReactGA.event({
                category: `Official - ${image}`,
                action: `Official - ${image}`,
                label: `Official - ${image}`,
              });
            }}>
            <button className="button">
              Official Website
            </button>
          </a>
          <a href={product} target="_blank" rel="noopener noreferrer" onClick={()=>{
              ReactGA.event({
                category: `Preorder/Buy - ${image}`,
                action: `Preorder/Buy - ${image}`,
                label: `Preorder/Buy - ${image}`,
              });
            }}>
            <button className="button">
              {
                bg === 'db-ii-bg' ? 'Preorder Now' : "Buy Now"
              }
            </button>
          </a>
        </div>
      </div>
  </>
  )
}


export default App;
